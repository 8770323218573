<template>
    <b-card v-if="task" title="Activity">

        <b-col v-if="task.messages.length > 0" cols="12">
            <app-timeline v-for="message in task.messages" :key="message.id">
                <app-timeline-item variant="secondary" :title="message.user.name" :subtitle="message.comment"
                    :time="new Date(message.created_at).toLocaleString()" />
            </app-timeline>
        </b-col>
        <b-col v-else cols="12">
            <app-timeline>
                <app-timeline-item variant="secondary" title="No activity yet" />
            </app-timeline>
        </b-col>

        <b-col v-if="task.owners.length > 0 && task.owners.some(owner => owner.id === currentUser.id)" cols="12">
            <b-col class="mt-1" cols="12">
                <b-form-group id="input-group-2" label-for="input-description">
                    <quill-editor style="min-height:200px;" ref="quilContent" id="quil-content" v-model="comment"
                        :options="editorOption" class="border-bottom-0" />
                    <div id="quill-toolbar" class="d-flex justify-content-end border-top-0">
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" value=""></button>
                        <button class="ql-align" value="center"></button>
                        <button class="ql-align" value="right"></button>
                        <button class="ql-align" value="justify"></button>
                        <button class="ql-link" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-button variant="outline-primary" @click="post">Comment</b-button>
            </b-col>
        </b-col>
    </b-card>
</template>
  
<script>
import {
    BButton, BFormGroup, BCol, BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@/components/atoms/tasks/AppTimeline.vue'
import AppTimelineItem from '@/components/atoms/tasks/AppTimelineItem.vue'
import { quillEditor } from 'vue-quill-editor'
import TaskMessages from '@/models/taskMessages'
import TaskActivityMessages from '@/models/taskActivityMessages'

export default {
    components: {
        BButton,
        BFormGroup,
        BCol,
        BCard,
        BImg,
        BMedia,
        BAvatar,
        AppTimeline,
        AppTimelineItem,
        quillEditor
    },
    data() {
        return {
            comment: '',
            editorOption: {
                modules: {
                    toolbar: '#quill-toolbar',
                },
                placeholder: 'Write a comment'
            },
            type: null
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.usersConfig.currentUser;
        },
        task() {
            return this.$store.state.taskActivityConfig.taskActivity !== null
                ? this.$store.state.taskActivityConfig.taskActivity
                : this.$store.state.taskConfig.task;
        }
    },
    async created() {
        this.type = this.$route.query.t;
        if (this.type) {
            await this.refetchData()
        } else {
            this.$router.push({ name: 'error-404' })
        }
    },
    methods: {
        async post() {
            if (!this.comment) return
            if (this.type === 'tasks') {
                const data = new TaskActivityMessages({
                    comment: this.comment,
                    fk_task_activity: this.$route.params.id,
                    fk_user: this.currentUser.id
                })
                await this.$store.dispatch('createTaskActivityMessage', data)
            } else {
                const data = new TaskMessages({
                    comment: this.comment,
                    fk_task_template_activity: this.$route.params.id,
                    fk_user: this.currentUser.id
                })
                await this.$store.dispatch('createMessage', data)
            }
            await this.refetchData()
            this.comment = ''
        },
        async refetchData() {
            if (this.type === 'tasks')
                await this.$store.dispatch('getTask', this.$route.params.id)
            else
                await this.$store.dispatch('getTaskActivity', this.$route.params.id)
        }
    }
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
    ::v-deep .vs__dropdown-toggle {
        padding-left: 0;
    }
}

#quil-content ::v-deep {
    >.ql-container {
        border-bottom: 0;
        min-height: inherit;
    }

    +#quill-toolbar {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}
</style>